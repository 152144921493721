import {
  mdiCogOutline,
  mdiAccountGroup,
  mdiListStatus,
  mdiAccountCashOutline,
  mdiCash100,
  mdiClipboardList,
  mdiFish,
  mdiAccountCog,
  mdiChartTree
} from '@mdi/js'

export default [
  {
    subheader: 'APPLICATION',
  },
  {
    title: 'Availability Report',
    icon: mdiListStatus,
    to: 'availability-list',
    resource: 'availability-list',
    action: 'manage'
  },
  {
    title: 'Orders',
    icon: mdiClipboardList,
    to: 'orders',
    resource: 'orders',
    action: 'manage'
  },
  {
    title: 'Invoices',
    icon: mdiAccountCashOutline,
    to: 'invoices',
    resource: 'invoices',
    action: 'manage'    
  },
  {
    title: 'Payments',
    icon: mdiCash100,
    to: 'payments', 
  },
  {
    title: 'Inventory',
    icon: mdiFish,
    to: '',
    children: [
      {
        title: 'Masters List',
        to: 'masters-list',
      },
      {
        title: 'Families',
        to: 'families',
      },
      {
        title: 'Zero Out Inventory',
        to: 'zero-inventory',
      },
      {
        title: 'Stock Report',
        to: 'stock-report',
      }
    ]
  },
  {
    title: 'Customers',
    icon: mdiAccountGroup,
    children: [
      {
        title: 'Customers List',
        to: 'customers',
      },
      {
        title: 'Price List',
        to: 'pricelists',
      },
      {
        title: 'Custom Codes',
        to: 'customer-product-codes',
      }
    ]
  },
  {
    title: 'Vendors',
    icon: mdiAccountCog,
    children: [
      {
        title: 'Vendors',
        to: 'vendors',
      },
      {
        title: 'Supplier Orders',
        to: 'supplier-orders',
      },
      {
        title: 'Supplier DOAs',
        to: 'supplier-doas',
      },
    ],
  },
  {
    title: 'Reports',
    icon: mdiChartTree,
    children: [
      {
        title: 'Daily Deposits',
        to: 'daily-deposits',
      },
      {
        title: 'DOF Status',
        to: 'dof-status',
      },
      {
        title: 'List of Shipments',
        to: 'list-of-shipments',
      },
      {
        title: 'Orders Breakdown',
        to: 'orders-breakdown',
      },
      {
        title: 'Sales',
        to: 'sales',
      },
      {
        title: 'Accounting Summary',
        to: 'accounting-summary',
      },
    ],
  },
  {
    title: 'Settings',
    icon: mdiCogOutline,
    children: [
      {
        title: 'Airlines',
        to: 'airlines',
      },
      {
        title: 'Countries',
        to: 'countries',
      },
      {
        title: 'Destinations',
        to: 'destinations',
      },
      {
        title: 'Email Templates',
        to: 'email-templates',
      },      
      {
        title: 'Roles',
        to: 'roles',
      },
      {
        title: 'Users',
        to: 'users',
      },
      {
        title: 'Registrations',
        to: 'registrations',
      },      
    ],
  },
]
